<template>
  <div>
    <navbar :activeNav="activeNav" />

    <!-- Section Home -->
    <section id="home" class="section gray-bg">
      <b-carousel
        :animated="'slide'"
        :has-drag="true"
        :arrow="false"
        :autoplay="true"
        :pause-hover="true"
        :pause-info="false"
        :pause-info-type="'is-primary'"
        :interval="7000"
        v-if="slideShow"
      >
        <!-- Slide Compony Profile -->
        <b-carousel-item>
          <section class="hero is-medium slide-0 is-bold pb-50">
            <div class="hero-content">
              <div class="columns is-multiline">
                <div
                  class="column is-12 has-text-right animated fadeInDown delay-1s fast"
                >
                  <div class="icon-robot">
                    <img src="@/assets/banner/01_02.png" class="image" />
                    <div class="chat-bubble mt-30">
                      <img src="@/assets/chat-bubbles.png" />
                      <div class="centered">
                        <div class="dot">.</div>
                        <div class="dot">.</div>
                        <div class="dot">.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12 has-text-right">
                  <div class="icon-desc animated fadeInUp delay-2s fast">
                    <img src="@/assets/banner/01_01.png" />
                  </div>
                </div>
              </div>
              <!-- <div class="icon-robot animated fadeIn delay-1s fast">
                <img src="@/assets/banner/01_02.png" class="image" />
                <div class="chat-bubble mt-30">
                  <img src="@/assets/chat-bubbles.png" />
                  <div class="centered">
                    <div class="dot">.</div>
                    <div class="dot">.</div>
                    <div class="dot">.</div>
                  </div>
                </div>
              </div>
              <div class="icon-desc animated fadeInRight delay-2s fast">
                <img src="@/assets/banner/01_01.png" class="image" />
              </div> -->
            </div>
          </section>
        </b-carousel-item>

        <!-- Slide Chatbotix -->
        <b-carousel-item>
          <section class="hero is-medium slide-1 is-bold pb-50">
            <div class="hero-content">
              <div class="columns">
                <div class="column is-6 has-text-left">
                  <div class="text mt-30 animated fadeInRight delay-1s">
                    <div class="slide-title" v-html="$t('slide1.title')"></div>
                    <div
                      class="slide-sub-title"
                      v-html="$t('slide1.desc')"
                    ></div>
                  </div>
                  <div class="icon-desc animated fadeInRight delay-2s fast">
                    <img src="@/assets/banner/01_01.png" class="image" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <!-- End Slide Chatbotix -->

        <!-- Slide Google Partner -->
        <!--<b-carousel-item>
          <section class="hero is-medium slide-1 is-bold pt-50 pb-50">
            <div class="hero-content">
              <div class="columns is-centered">
                <div class="column has-text-centered">
                  <div class="section-title">
                    <h4 class="sub-title">G<span>oo</span>gle Partner</h4>
                  </div>
                </div>
                <div class="column has-text-centered">
                  <img src="@/assets/google-partner.png" />
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>-->
      </b-carousel>
    </section>
    <!-- End Section Home -->

    <!-- Section About Us -->
    <section id="about-us" class="section white-bg pt-80 pb-80">
      <div class="container">
        <div class="section-title text-center">
          <h6
            class="title"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            {{ $t('aboutUs.title') }}
          </h6>
          <h4
            class="sub-title"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="500"
            v-html="$t('aboutUs.subTitle')"
          ></h4>
          <hr class="line" data-aos="zoom-in" data-aos-delay="900" />
        </div>
        <div class="mt-50">
          <div class="columns">
            <div class="column has-text-right">
              <div
                class="video-content"
                data-aos="fade-right"
                data-aos-delay="500"
              >
                <div class="video-title">
                  {{ $t('aboutUs.videoTitle') }}
                </div>
                <div class="video-desc" v-html="$t('aboutUs.videoDesc')"></div>
              </div>
            </div>
            <div class="column">
              <div id="player-overlay" data-aos="zoom-in" data-aos-delay="900">
                <!-- <video controls poster="@/assets/video/poster.png">
                  <source src="@/assets/video/small.mp4" type="video/mp4" />
                  <source src="@/assets/video/small.webm" type="video/webm" />
                  Your browser does not support the video tag.
                </video> -->
                <iframe
                  class="youtube"
                  src="https://www.youtube.com/embed/wnSAeXuItKw?controls=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section About Us -->

    <!-- Section Solutions -->
    <section id="solutions" class="section gray-bg pt-80 pb-80">
      <div class="container">
        <div class="section-title text-center">
          <h6
            class="title"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            {{ $t('ourSolutions.title') }}
          </h6>
          <h4
            class="sub-title"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="500"
            v-html="$t('ourSolutions.subTitle')"
          ></h4>
          <hr class="line" data-aos="zoom-in" data-aos-delay="900" />
        </div>
        <div class="columns is-multiline is-centered">
          <div
            v-for="(solution, index) in solutions"
            v-bind:key="index"
            class="column has-text-centered"
            data-aos="fade-up"
            :data-aos-delay="index * 100"
            @click="showSolution(index)"
          >
            <div class="flip-container">
              <div class="front">
                <div class="card-content">
                  <div class="card-icon">
                    <img :src="solution.icon" />
                    <!-- <b-icon pack="fas" :icon="item.icon"></b-icon> -->
                  </div>
                  <div class="card-title">
                    {{ translate(solution.title) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
        <div class="card card-modal">
          <div class="card-image">
            <div class="card-icon">
              <img :src="solutions[solutionSelected].icon" />
              <!-- <b-icon pack="fas" :icon="item.icon"></b-icon> -->
            </div>
            <div class="card-title">
              {{ translate(solutions[solutionSelected].title) }}
            </div>
          </div>
          <div class="card-content">
            <div
              class="content"
              v-html="$t(solutions[solutionSelected].desc)"
            ></div>
          </div>
        </div>
      </b-modal>
    </section>
    <!-- End Section Solutions -->

    <!-- Section Our Customers -->
    <section id="our-customers" class="section white-bg pt-80 pb-80">
      <div class="container">
        <div class="section-title text-center">
          <h6
            class="title"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            {{ $t('ourCustomers.title') }}
          </h6>
          <h4
            class="sub-title"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="500"
            v-html="$t('ourCustomers.subTitle')"
          ></h4>
          <hr class="line" data-aos="zoom-in" data-aos-delay="900" />
        </div>

        <div class="columns is-multiline is-mobile is-centered">
          <div
            v-for="(customer, index) in customers"
            v-bind:key="index"
            class="column is-one-third-mobile is-one-fifth-tablet is-one-fifth-desktop is-one-fifth-widescreen"
            data-aos="fade-up"
            :data-aos-delay="index * 100"
          >
            <div class="circle">
              <img :src="customer" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Our Customers -->

    <!-- Section News -->
    <section id="news" class="section gray-bg pt-80 pb-80">
      <div class="container">
        <div class="section-title text-center">
          <h6
            class="title"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            {{ $t('news.title') }}
          </h6>
          <h4
            class="sub-title"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="500"
            v-html="$t('news.subTitle')"
          ></h4>
          <hr class="line" data-aos="zoom-in" data-aos-delay="900" />
        </div>
        <div class="mt-50">
          <div class="columns is-centered">
            <div class="column is-12 has-text-centered">
              <div id="player-overlay" data-aos="zoom-in" data-aos-delay="900">
                <!-- <video controls poster="@/assets/video/poster.png">
                  <source src="@/assets/video/small.mp4" type="video/mp4" />
                  <source src="@/assets/video/small.webm" type="video/webm" />
                  Your browser does not support the video tag.
                </video> -->
                <iframe
                  class="youtube"
                  src="https://www.youtube.com/embed/y0h4Pvok-uU?controls=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div
                class="video-content"
                data-aos="fade-right"
                data-aos-delay="500"
              >
                <div class="video-subtitle has-text-centered mt-10">
                  {{ $t('news.videoTitle') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section About Us -->

    <!-- Section Contact Us -->
    <section id="contact-us" class="section white-bg pt-80 pb-80">
      <div class="container">
        <div class="section-title text-center">
          <h6
            class="title"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            {{ $t('contactUs.title') }}
          </h6>
          <h4
            class="sub-title"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="500"
            v-html="$t('contactUs.subTitle')"
          ></h4>
          <hr class="line" data-aos="zoom-in" data-aos-delay="900" />
        </div>

        <div class="mt-50">
          <div class="company-name" data-aos="zoom-in" data-aos-delay="100">
            True Voice Co., Ltd.
          </div>
          <div
            class="contact-info-icon brand mt-30 flex justify-content-center"
          >
            <div data-aos="slide-left" data-aos-delay="300">
              <a href="https://www.facebook.com/TrueVoiceThai" target="_blank"
                ><b-icon pack="fab" icon="facebook-f" class="facebook"></b-icon
              ></a>
            </div>
            <div data-aos="fade" data-aos-delay="500">
              <a
                href="https://www.youtube.com/channel/UCYrEEgHPiKdo3ScNAaD-JEg"
                target="_blank"
                ><b-icon pack="fab" icon="youtube" class="youtube"></b-icon
              ></a>
            </div>
          </div>
        </div>
        <div class="mt-50">
          <div class="columns is-centered">
            <div class="column">
              <div class="flex" data-aos="fade-up" data-aos-delay="100">
                <div class="contact-info-icon">
                  <b-icon pack="fas" icon="phone-alt"></b-icon>
                </div>
                <div class="contact-info-content">
                  <p class="text">
                    <a href="tel:+66953659287">
                      +669 5365 9287
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="flex" data-aos="fade-up" data-aos-delay="500">
                <div class="contact-info-icon">
                  <b-icon pack="fas" icon="envelope"></b-icon>
                </div>
                <div class="contact-info-content">
                  <p class="text">
                    info@truevoice.co.th
                  </p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="flex" data-aos="fade-up" data-aos-delay="900">
                <div class="contact-info-icon">
                  <b-icon pack="fas" icon="map-marker-alt"></b-icon>
                </div>
                <div class="contact-info-content">
                  <p class="text" v-html="$t('contactUs.address')"></p>
                  <a :href="googleDirection" target="_blank"
                    ><p class="text" v-html="$t('contactUs.direction')"></p
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-30">
          <div class="form-title">{{ $t('contactUs.formTitle') }}</div>
          <form id="sendEmail" @submit="contactForm" method="post">
            <div class="columns">
              <div class="column">
                <b-field
                  :type="errSubject != '' ? 'is-danger' : ''"
                  :message="errSubject"
                >
                  <b-input
                    type="text"
                    v-model="subject"
                    name="subject"
                    :placeholder="$t('contactUs.subject.title')"
                    @focus="clearError('errSubject')"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  :type="errEmail != '' ? 'is-danger' : ''"
                  :message="errEmail"
                >
                  <b-input
                    type="text"
                    v-model="email"
                    name="email"
                    :placeholder="$t('contactUs.email.title')"
                    @focus="clearError('errEmail')"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                  :type="errMessage != '' ? 'is-danger' : ''"
                  :message="errMessage"
                >
                  <b-input
                    type="textarea"
                    v-model="message"
                    name="message"
                    :placeholder="$t('contactUs.message.title')"
                    @focus="clearError('errMessage')"
                    maxlength="2000"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column has-text-centered">
                <div class="buttons">
                  <button type="submit" class="main-btn center">
                    {{ $t('contactUs.sendNow') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- End Section Contact Us -->
  </div>
</template>

<script>
import AOS from 'aos'

import Navbar from '@/components/Navbar'

import solBanking from '@/assets/solutions/banking.png'
import solTelecom from '@/assets/solutions/telecom.png'
import solAirline from '@/assets/solutions/airline.png'
import solHotel from '@/assets/solutions/hotel.png'
import solInsurance from '@/assets/solutions/insurance.png'
import solHospital from '@/assets/solutions/hospital.png'
import solEntertainment from '@/assets/solutions/entertainment.png'
import solAutomation from '@/assets/solutions/automation.png'

import logoTrueMari from '@/assets/customers/logo-true-mari.jpg'
import logoScb from '@/assets/customers/logo-scb.png'
import logoMg from '@/assets/customers/logo-mg.jpg'
import logoKbank from '@/assets/customers/logo-kbank.png'
import logo2c2p from '@/assets/customers/logo-2c2p.png'
import logoThaiSmile from '@/assets/customers/logo-thai-smile.png'
import logoKrungsri from '@/assets/customers/logo-krungsri.png'
import logoBangkokInsurance from '@/assets/customers/logo-bangkok-insurance.png'
import logoSf from '@/assets/customers/logo-sf.jpeg'

export default {
  name: 'Home',
  components: {
    Navbar,
  },
  data() {
    return {
      isModalActive: false,
      solutionSelected: 0,
      activeNav: 'home',
      carousel: 0,
      slideShow: false,
      solutions: [
        {
          title: 'ourSolutions.banking.title',
          icon: solBanking,
          page: '/solutions/banking',
          desc: 'ourSolutions.banking.text',
        },
        {
          title: 'ourSolutions.telecom.title',
          icon: solTelecom,
          page: '/solutions/telecom',
          desc: 'ourSolutions.telecom.text',
        },
        {
          title: 'ourSolutions.airline.title',
          icon: solAirline,
          page: '/solutions/airline',
          desc: 'ourSolutions.airline.text',
        },
        {
          title: 'ourSolutions.hotel.title',
          icon: solHotel,
          page: '/solutions/hotel',
          desc: 'ourSolutions.hotel.text',
        },
        {
          title: 'ourSolutions.insurance.title',
          icon: solInsurance,
          page: '/solutions/insurance',
          desc: 'ourSolutions.insurance.text',
        },
        {
          title: 'ourSolutions.hospital.title',
          icon: solHospital,
          page: '/solutions/hospital',
          desc: 'ourSolutions.hospital.text',
        },
        {
          title: 'ourSolutions.entertainment.title',
          icon: solEntertainment,
          page: '/solutions/entertainment',
          desc: 'ourSolutions.entertainment.text',
        },
        {
          title: 'ourSolutions.automation.title',
          icon: solAutomation,
          page: '/solutions/automation',
          desc: 'ourSolutions.automation.text',
        },
      ],
      customers: [
        logoTrueMari,
        logoScb,
        logoMg,
        logoKbank,
        logo2c2p,
        logoThaiSmile,
        logoKrungsri,
        logoBangkokInsurance,
        logoSf,
      ],
      email: '',
      subject: '',
      message: '',
      errEmail: '',
      errSubject: '',
      errMessage: '',
      googleDirection:
        'https://www.google.com/maps/dir//True+Voice+(ทรู+วอยซ์)+-+Thai+Speech+Recognition+Company+1,3,5,7+อาคาร+ซี+พี+ทาวเวอร์+2+ชั้น+12,+ถนนรัชดาภิเษก,+ดินแดง,+Bangkok+10400/@13.7577352,100.5626963,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x30e29e8d14ec9e8b:0xf67196b7a20f75fc!2m2!1d100.564885!2d13.75773',
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    setTimeout(() => {
      this.slideShow = true
    }, 500)
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    handleScroll(event) {
      const children = this.$el.querySelectorAll('section.section')

      children.forEach(element => {
        const top = element.offsetTop - 52
        const bottom = top + element.scrollHeight
        if (window.scrollY >= top && window.scrollY < bottom)
          this.activeNav = element.id
      })
    },
    async contactForm(e) {
      e.preventDefault()

      if (this.subject == '') {
        this.errSubject = this.translate('contactUs.subject.isNull')
        return
      }
      if (this.email == '') {
        this.errEmail = this.translate('contactUs.email.isNull')
        return
      }
      if (!this.validEmail(this.email)) {
        this.errEmail = this.translate('contactUs.email.invalid')
        return
      }
      if (this.message == '') {
        this.errMessage = this.translate('contactUs.message.isNull')
        return
      }

      const loadingComponent = this.$buefy.loading.open()

      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      const data = {
        email: this.email,
        subject: this.subject,
        message: this.message,
        action: 'contactForm',
        token: token,
      }

      this.$axios
        .post(`/mailer/send`, data)
        .then(response => {
          this.email = ''
          this.subject = ''
          this.message = ''

          this.$buefy.toast.open({
            duration: 5000,
            message: response.data,
            type: 'is-success',
            position: 'is-bottom',
          })
        })
        .catch(error => {
          // console.log(error)

          this.$buefy.toast.open({
            duration: 5000,
            message: error,
            type: 'is-danger',
            position: 'is-bottom',
          })
        })
        .finally(() => loadingComponent.close())
    },
    clearError(error) {
      this[error] = ''
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    translate(text) {
      return this.$t(text)
    },
    showSolution(index) {
      this.solutionSelected = index
      this.isModalActive = true
    },
  },
}
</script>

<style scoped lang="scss">
#home {
  padding: 0px;
  // height: calc(100vh - 52px);
  height: 570px;

  .slide-0 {
    background-image: url('~@/assets/banner/01_00.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img.banner {
      width: 120px;
      height: auto;
    }

    .line-banner {
      margin-top: 50px;
    }

    .text-banner {
      text-align: center;
      font-size: 1.2em;
      font-weight: 700;
    }
  }

  .slide-1 {
    background-image: url('~@/assets/banner/02_00.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .slide-title {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;

      /deep/ .primary {
        color: $primary !important;
      }
    }

    .slide-sub-title {
      margin: 40px 0 80px 0;
      font-size: 20px;
    }

    // img.banner {
    //   width: 120px;
    //   height: auto;
    // }

    // .line-banner {
    //   margin-top: 50px;
    // }

    // .text-banner {
    //   text-align: center;
    //   font-size: 1.2em;
    //   font-weight: 700;
    // }

    // img.flow {
    //   width: 650px;
    // }
  }
}

@media (min-width: 767px) {
  .slide-0 {
    .icon-robot {
      display: flex;
      float: right;
      width: 150px;
      height: 150px;
      margin-right: 100px;
      img.image {
        width: 150px;
        height: 150px;
        margin-left: -100px;
        margin-top: 100px;
      }
    }
    .icon-desc {
      margin-right: 100px;

      img {
        width: 350px;
        margin-top: 100px;
      }
    }
  }
  .slide-1 {
    .icon-desc {
      margin-top: 30px;
    }
    .slide-title {
      margin-top: 100px;
    }
  }
}

@media (max-width: 767px) {
  .slide-0 {
    .icon-robot {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
      height: 150px;
      margin-left: 100px;
      img.image {
        width: 150px;
        height: 150px;
        margin-left: -100px;
        margin-top: 100px;
      }
    }
    .icon-desc {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 200px;
      width: 200px;
      img {
        width: 200px;
        margin-top: 0px;
      }
    }
  }

  .slide-1 {
    .slide-title,
    .slide-sub-title,
    .icon-desc {
      background: rgba(255, 255, 255, 0.8);
      padding: 10px;
    }
    .icon-desc {
      margin-top: -40px;
    }
    .slide-title {
      font-size: 32px !important;
      line-height: 28px !important;
    }

    .slide-sub-title {
      margin: 20px 0 70px 0 !important;
      font-size: 20px !important;
    }
  }
}

button.center {
  margin-left: auto;
  margin-right: auto;
}

.form-title {
  margin: 60px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.chat-bubble {
  position: relative;
  text-align: center;
  color: white;

  img {
    width: 100px;
    height: 100px;
  }

  .centered {
    font-size: 6em;
    position: absolute;
    width: 100px;
    top: 10%;
    left: 40px;
    transform: translate(-50%, -50%);

    .dot {
      display: inline-block;
      animation-name: blink;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}
</style>
