<template>
  <footer id="footer">
    <div class="footer-copyright text-center">
      <p class="text">
        © 2020 Crafted by <a href="#" rel="nofollow">True Voice</a> All Rights
        Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
