<template>
  <b-navbar id="navbar" v-bind:fixed-top="true">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="@/assets/logo.png"
          alt="We are the leader in virtual agents"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item :active="activeNav === 'home'" v-scroll-to="'#home'">
        {{ $t('navbar.home') }}
      </b-navbar-item>
      <b-navbar-item
        :active="activeNav === 'about-us'"
        v-scroll-to="'#about-us'"
      >
        {{ $t('navbar.aboutUs') }}
      </b-navbar-item>
      <b-navbar-item
        :active="activeNav === 'solutions'"
        v-scroll-to="'#solutions'"
      >
        {{ $t('navbar.solutions') }}
      </b-navbar-item>
      <b-navbar-item
        :active="activeNav === 'our-customers'"
        v-scroll-to="'#our-customers'"
      >
        {{ $t('navbar.ourCustomers') }}
      </b-navbar-item>
      <b-navbar-item
        :active="activeNav === 'news'"
        v-scroll-to="'#news'"
      >
        {{ $t('navbar.news') }}
      </b-navbar-item>
      <b-navbar-item
        :active="activeNav === 'contact-us'"
        v-scroll-to="'#contact-us'"
      >
        {{ $t('navbar.contactUs') }}
      </b-navbar-item>
      <!-- <b-navbar-dropdown label="Info">
        <b-navbar-item href="#solution">
          About
        </b-navbar-item>
        <b-navbar-item href="#our-customer">
          Contact
        </b-navbar-item>
      </b-navbar-dropdown> -->
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <!-- <span
          class="switch-lang"
          @click="$i18n.locale = 'en'"
          :active="$i18n.locale == 'en'"
          >EN</span
        >
        <span class="gray">/</span>
        <span
          class="switch-lang"
          @click="$i18n.locale = 'th'"
          :active="$i18n.locale == 'th'"
          >TH</span
        > -->
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navber',
  props: {
    activeNav: {
      type: String,
      default: 'home'
    }
  }
}
</script>
